#aboutUsLandingPageSection {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 50px;
}

#aboutUsLandingPageSection h1 {
    font-size: 60px;
    width: 60%;
}



#aboutUsLandingPageSection img {
    width: 20px;
}

/************ US ************/

#aboutUsImages {
    display: flex;
    overflow: hidden;
    margin-top: 15vh;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

#aboutUsImages video {
    width: 60%;
}


/************ YEARS ************/

#aboutUsYearsSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 80px;
    padding: 20vh 0;
}

#aboutUsYearsSection div {
    width: 550px;
}

#aboutUsYearsSection div {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#aboutUsYearsSection h1 {
    font-size: 80px;
}


#aboutUsYearsSection .dots {
 
    gap: 0px;
}

.dots span {
    font-size: 60px;
}


/************ SECURITY ************/

#aboutUsSecuritySection {
    padding: 40vh 15%;
    display: flex;
    justify-content: space-between;
}

#aboutUsSecurityText {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

#aboutUsSecuritySection img {
    width: 400px;
    object-fit: contain;
}

/************ Feedback ************/
#aboutUsFeedbackSection {
    padding: 18vh 30%;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 50px;
}

#feedbackDescription {
    width: 70%;
}

#aboutUsFeedbackSection form {
    display: flex;
    width: 70%;
    text-align: left;
    gap: 20px;
}







/************ RESPONSIVE ************/

@media screen and (max-width: 1440px) {
    #aboutUsLandingPageSection h1 {
        width: 90%;
    }
}

@media screen and (max-width: 1200px) {
    #aboutUsImages {
        flex-direction: column;
        height: auto;
    }

    #aboutUsImages img {
        width: 100%;
        height: 60vh;
    }

    #aboutUsSecuritySection {
        flex-direction: column;
        gap: 80px;
        padding: 40vh 10%;
        align-items: center;
        text-align: center;
    }

    #aboutUsSecurityText {
        width: 100%;
    }

    #aboutUsSecuritySection img {
        width: 300px;
    }


    #aboutUsFeedbackSection {
        padding: 18vh 10%;
    }

}

@media screen and (max-width: 680px) {
    #aboutUsLandingPageSection h1 {
        font-size: 40px;
        width: 90%;
    }

    #aboutUsImages img {
        width: 100%;
        height: 40vh;
    }
    #aboutUsImages video {
        width: 80%;
    }

    #aboutUsYearsSection {
        gap: 30px;
    }
    
    #aboutUsYearsSection h1 {
        font-size: 40px;
    }

    #aboutUsYearsSection div {
        width: 90%;
    }

    .dots span {
        font-size: 40px;
    }


    
    #aboutUsSecuritySection {
        padding: 40vh 5%;
    }
    #aboutUsSecuritySection img {
        width: 60%
    }

    
    #aboutUsFeedbackSection {
        padding: 18vh 5%;
    }

    #feedbackDescription, #aboutUsFeedbackSection form {
        width: 100%;
    }
}