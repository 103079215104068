
#pricingSection {
    
}


#pricingPlans {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    text-align: left;
    padding: 0 10%;
}

.plan_container {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 30px;
    border: 1px solid var(--mid);
    border-radius: 16px;
    padding:30px;
    justify-content: space-between;
}

.plan {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.plan_container a {
    border-radius: 10px;
    width: 100%;
}

#pricingPlans hr {
    width: 2px;
    background-color: var(--light);
    border: 0;
    outline: none;
}

.planTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.recommended {
    padding: 2px 10px;
    background-color: var(--main-blue);
    border-radius: 40px;
    width:fit-content;
    display: flex;
    gap: 4px;
    align-items: center;
}

.recommended p {
    color: var(--white);
    font-size: 14px;
}

.planDescription, .planIncludes {
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.planTitle {
    color: var(--black);    
}

.includedFeatures {
    display: flex;
    align-items: center;
    gap: 10px;
}

.includedFeatures img {
    width: 20px;
    min-width: 20px;
}

#pricingDiscount {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 0 7%;
    margin-top: 10vh;
}

#discountContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;

}

#discountContainer p {
    width: 30%;
}


#discountTableDiv {
    width: 40%;
}

.pricing_with_tag {
    display: flex;
    align-items: center;
    gap: 8px;
}

.tag {
    background-color: var(--light);
    display: flex;
    padding: 2px 8px;
    width: fit-content;
    border-radius: 20px;
    font-size: 14px;
}

#discountTableDiv table {
    border-collapse: collapse;
    width: 100%;
}

#discountTableDiv table tr {
    height: 50px;
    border-bottom: 2px solid var(--light);
}


#discountTableDiv table td:last-child {
    color: var(--main-green);
}

.pricing_club_table {
    display: flex;
    flex-direction: column;
}

.pricing_club_table div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0;
    border-bottom: 1px solid var(--light);
}

@media screen and (max-width: 1800px) {

    #pricingPlans {
        gap: 20px;
        padding: 0;
    }

}
@media screen and (max-width: 1200px) {
    #pricingPlans {
        flex-direction: column;
    }
   
}
