/** HEADER STYLE **/

.header_container {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 8vh;
    padding: 0 10%;
    border-bottom: 1px solid var(--grey-light, #ECECEC);
    width: 100%;
    background: rgba(255, 255, 255, 0.80);
    backdrop-filter: blur(7.5px);
    flex-wrap: wrap;
    z-index: 999;
}

.logo_container {
    align-items: center;
    display: flex;
}

#logo {
    width: 120px;

}

nav {
    display: flex;
    gap: 50px;
}

nav a {
    color: var(--normal);
}

nav a.active {
    color: var(--black);
    font-weight: 500;
}

.navButton {
    display: flex;
    gap: 8px;
}

/** COMING SOON */
.coming_soon {
    display: flex;
    width: 100%;
    background-color: var(--main-blue);
    align-items: center;
    padding: 6px 10%;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
}

.coming_soon_timer {
    display: flex;
    gap: 8px;
}

.coming_soon_timer p {
    background-color: rgba(255, 255, 255, 0.40);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    text-align: center;
    color: var(--white);
    padding: 0 8px;
}

.coming_soon h4 {
    font-size: 16px;
    color: var(--white);
}

@media screen and (max-width: 680px) {
    header {
        padding: 0 5%;
        height: 10vh;
    }
    .coming_soon {
        padding: 6px 5%;
    }
    .coming_soon_timer p {
        font-size: 14px;
    }
}