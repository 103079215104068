

#contactSection {
    display: flex;
    justify-content: space-between;
    height: 100vh;
    align-items: center;
}

#contactContent {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 50px;
}

#contactContent #description {
    width: 70%;
}

#contactContent div {
    display: flex;
    gap: 12px;
}

#contactSection form {
    width: 30%;
    min-width: 300px;
}



/********* RESPONSIVE *************/

@media screen and (max-width: 1200px) { 
    #contactSection form {
     width: 45%;
    } 
}


@media screen and (max-width: 1000px) {
   #contactSection {
        flex-direction: column;
        height: auto;
   }

   #contactContent, #contactContent #description, #contactSection form {
    width: 100%;
   }

   #contactSection form {
    margin-top: 50px;
   }
}