.contact_form_input_container {
    display: flex;
    gap: 8px;
    flex-direction: column;
}

.contact_form button {
    align-self: flex-end;
}

