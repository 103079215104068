#landingPageSection {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#landingPageText {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    text-align: center;
    gap: 30px;
}

#landingPageText p {
    width: 450px;
}

#landingPageText img {
    width: 20px;
}

#landingPageMockup {
    width: 60%;
    margin-top: -80px;
    z-index: -1;
    object-fit: contain;
}
.homeReferencesContainer {
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* Each column takes an equal fraction of the available space */
}




/**** Platform ******/


#homePlatformSection {
    padding-top: 10vh;
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    gap: 200px;
    text-align: center;
    color: white;
    align-items: center;
}

#platformTitles {
    overflow: hidden;
    width: 100%;
}

#platformTitles p {
    font-size: 80px;
    text-transform: uppercase;
    letter-spacing: -4px;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 900;
    color: rgba(255, 255, 255, 0.10);
    line-height: 90px;
}

#platformTitles p span {
    color: white;
    opacity: 1;
    font-weight: 900;
}

#allPlatformsDiv {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
}

#allPlatformsDiv p {
    width: 60%;
}


#homeFeatureSection {
    padding-top: 18vh;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

#homeFeaturesTitle {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

#homeFeaturesDiv {
    display: flex;
    gap: 16px;
    height: 60vh;
    overflow: hidden;
}



#homeFeaturesDiv .homeFeatureDiv {
    flex: 1;
}

.homeFeatureDiv {
    background-color: var(--light);
    padding: 16px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.homeFeaturesDarkBackground {
    background-color: var(--dark);
}

.homeFeatureSmall {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.homeFeatureSmall .homeFeatureDiv {
    flex: 1;
}

#homeReferencesSection {
    display: flex;
    flex-direction: column;
    gap: 50px;
    text-align: center;
    height: 100vh;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: -999;
}

#homeReferencesSection div {
    display: flex;
    align-content: center;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
    
}

#homeReferencesSection div img {
    width: 200px;
    z-index: -999;
}

#homeReferencesSection div {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    opacity: 0.7;
}


#homeActionSection {
    display: flex;
    gap: 16px;
    padding: 0 15% 18vh 15%;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;
}

.homeActionDiv {
    flex: 1;
    background-color: var(--light);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    text-align: center;
    padding: 50px 50px;
    justify-content: flex-start;
    border-radius: 12px;
}

.homeActionDiv p{
    width: 100%;
}


.qrCodes {
    background-color: black;
    overflow: hidden;
    position: relative;
    z-index: 1;
    padding-top: 20vh;
    padding-bottom: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .qrCodes:hover {
    cursor: none;
  }

  .qrCodesImages {
        display: flex;
        align-items: center;
        gap: 300px;
  }

  .qrCodesImages p {
    color: black;
  }

  .qrCodesImage {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .qrCodes .qrCodesImage img {
    width: 250px;
  }

  .qrCodes .qrCodesImage .qrCodesImage_hide {
    width: 120px;
    margin-bottom: 30px;
  }


  #googlePlayStore {
    margin-top: 150px;
  }

  .cursor {
    width: 400px; 
    height: 400px; 
    border-radius: 50%;
    position: absolute;
    pointer-events: none;
    background-color: white;
    z-index: -1;
    display: none;
    margin-top: -200px;
    margin-left: -200px;
  }
  
  
  .qrCodes.circle-mode .cursor {
    display: block;
  }

  .callToActionMobile {
    display: none;
  }
  
  
@media screen and (max-width: 1200px) {    
    #landingPageMockup {
        width: 80%;     
        margin-top: -50px;  
    }
    .callToActionMobile {
        display: flex;
    }
}   

@media screen and (max-width: 680px) {
    #landingPageMockup {
        width: 90%;     
        margin-top: 0;  
    }
    #landingPageText p {
        width: 90%;
    }
    #platformTitles p {
        font-size: 50px;
        line-height: 55px;
    }
    #allPlatformsDiv p {
        width: 90%;
    }

    .qrCodesImages {
        background-color:var(--white);
        gap: 30px;
        justify-content: center;
        width: 350px; 
        height: 350px; 
        border-radius: 50%;
    }

    .qrCodesImage {
        z-index:999999;

    }

    .qrCodesImage:hover {
        cursor: pointer;
    }

    .qrCodes.circle-mode .cursor {
        display: none;
      }

    .qrCodes .qrCodesImage img {
        width: 100px;
    }

    .qrCodes .qrCodesImage p {
        font-size: 15px;
    }

    #googlePlayStore {
        margin-top: 0;
    }

    #homeFeaturesTitle {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
    }

    #homeFeaturesDiv {
        gap: 4px;
        width: 100%;
        height: fit-content;
        display: grid;
        grid-template-columns: auto auto auto auto;
    }

    #homeFeaturesDiv .homeFeatureDiv {
       flex:content;
    }

    .homeFeatureDiv {
        border-radius: 0 ;
    }

    .homeFeatureSmall {
        gap: 4px;
        flex-direction: row;
        grid-column: span 3;
    }

    #homeFeaturesDiv img{
        width: 100%;
    }

    #homeReferencesSection {
        height: auto;
        padding: 18vh 0;
    }

    #homeReferencesSection div {
        display: grid;
        grid-template-columns: auto auto auto;
        padding: 0 10%;
        gap: 16px;
        row-gap: 30px;
    }

    #homeReferencesSection div img {
        width: 100%;
    }

    #homeActionSection {
        padding: 0 5% 18vh 5%;
        flex-direction: column;
        gap: 8px;
    }

    .homeActionDiv {
        height: auto;
        width: 100%;
        gap: 16px;
        padding: 16px 16px 24px 16px;
        min-height: 0;
    }

    .homeActionDiv p {
        width: 100%;
        color: var(--normal);
    }

    .homeActionDiv .navButton a {
        border-radius: 8px;
        width: 100%;
    }

}