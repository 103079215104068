/* Header.css */

  
  /* HamburgerMenu.css */

  
  .hamburgerButton {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }
  
  .hamburgerLine {
    width: 100%;
    height: 2px;
    background-color: var(--dark);
    transition: all 0.3s ease-in-out;
  }
  
  .hamburgerButton.open .hamburgerLine:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  
  .hamburgerButton.open .hamburgerLine:nth-child(2) {
    opacity: 0;
  }
  
  .hamburgerButton.open .hamburgerLine:nth-child(3) {
    transform: translateY(-10px) rotate(-45deg);
  }

 .menuContent {
    position: absolute;    
    background-color: var(--white);
    padding: 0 10%;
    display: inline-flex;
    flex-direction: column;
    gap: 50px;
    height: 100vh;
    width: 100%;
    left: 0;
    top: 0;
    justify-content: center;
    z-index: -1;
  }
  
  .menuItems {
    display: flex;
    flex-direction: column;
  }

  
  .menuItems a {
    font-size: 25px;
    font-weight: 500;
  }
  
  @media screen and (max-width: 680px) {
    .menuContent {
      padding: 0 5%;
    }
}

