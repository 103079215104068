footer {
    
}

.footer_top div {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.footer_top {
    background-color: var(--light);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 30px 10%;
    align-items: center;
}

.footer_bottom {
    background-color: var(--normal);
    display: flex;
    padding: 10px 10%;
    align-items: center;
    gap: 20px;
}

.footer_bottom_link {
    display: flex;
    align-items: center;
    justify-content: center;
}

.social_media_icon {
    color: var(--white);
    transition: all 0.4s;
}

.social_media_icon:hover {
    color: var(--mid);
}

#footerRights {
    align-self: flex-start;
}

#changeLanguages a{
    display: flex;
    gap: 10px;
    background-color: var(--white);
    padding: 5px 8px;
    border-radius: 8px;

}

#changeLanguages a:hover {
    cursor: pointer;
}

#changeLanguages a img {
    width: 20px;
    object-fit: contain;    
}

#changeLanguages a.activeLanguage {
    background-color: var(--mid);
    border: 1px solid var(--dark);

}




/****************************** NOTEBOOK ******************************/
@media screen and (max-width: 1200px) {
    .footer_top {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 30px;
    }
    #changeLanguages a {
        width: 120px;
    }
}

/****************************** MOBILE ******************************/
@media screen and (max-width: 680px) {
    .footer_top {
        display: grid;
        grid-template-columns: auto;
        gap: 30px;
        padding: 30px 5%;
    }
}
