#faqSection {
    gap: 80px;
    width: 50%;
    align-items: center;
    margin: 0 auto;
}

#faqSection input {
    width: fit-content;
    align-self: flex-start;
    display: flex;
    background-color: var(--white);
    border-radius: 12px;
    padding-right: 16px;
    outline: none;    
    border-bottom: 1px solid var(--normal);
    border-radius: 0;
    padding: 10px 0px;
}

.faqSearch input {
    outline: none;
}

.faqSearch input:focus ~ .faqSearch {
    border: 1px solid blue;
  }

.faqSearchIcon {
    width: 20px;
    opacity: 0.6;
}

.faqList  {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

#faqSection .horizontal_line {
    width: 100%;
    height: 1px;
    background-color: var(--light);
    border: none;
    outline: none;
}

.faqQuestion {
    background-color: var(--white);
    padding: 10px 0px;
    border-radius: 0px;
    display: flex;
    justify-content: space-between;    
    align-items: center;
    text-align: left;
}

.faqQuestion:hover {
    cursor: pointer;
}
.faqDropdownIcon {
    width: 16px;
    transform: all 0.8s;
}

.faqDropdownIcon.active {
    transform: rotate(180deg);
}

.faqAnswer {
    display: flex;
    padding: 8px 0;
    text-align: left;
    color: var(--normal);
}

.faqAnswer * {
    max-width: 100%;

}


@media screen and (max-width: 1200px) {
    .faqList {
        width: 100%;
    }
}

@media screen and (max-width: 680px) {
    #faqSection {
        gap: 50px;
    }
}