:root {
    --main-blue: #1070FF;
    --light-blue: #CFE2FF;
    --transparent-blue: rgba(16, 112, 255, 0.20);
    --white: #FFFFFF;
    --light: #ECECEC;
    --mid: #D6D6D6;
    --normal: #808080;
    --dark: #383838;
    --black: #000000;
    --main-green: #0CA959;
    --light-green: rgba(12, 169, 89, 0.20);
    --red_transparent: rgba(244, 67, 54, 0.20);
    --red_light: #FDD9D7;
    --red_main: #F44336;
    --green_main: #0CA959;
    --green_transparent: rgba(12, 169, 89, 0.20);
    --green_light: #CEEEDE;
    --orange_main: #FFB84E;
    --orange_transparent: rgba(255, 184, 78, 0.20);
    --purple_main: #AE4FDA;
    --purple_transparent: rgba(174, 79, 218, 0.20)    
}

* {
    box-sizing: border-box;
    font-family: "Satoshi-Variable", sans-serif;
    font-weight: 500;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}



h1 {font-size: 55px; font-weight: 700;}
h2 {font-size: 40px; font-weight: 700;}
h3 {font-size: 30px; font-weight: 600;}
h4 {font-size: 20px; font-weight: 600;}
h5 {font-size: 18px;  font-weight: 700;}
h6 {font-size: 18px;}
input, a, p, textarea, th, td, select, option, li, button {font-size: 16px; color: var(--dark);line-height: 150%;}

a,button {text-decoration: none;}
a:hover, button:hover {
    cursor: pointer;
}

.white{
    color: var(--white);
}

.opacityNone {
    opacity: 0;
}

label {
    font-size: 15px;
    color: var(--dark);
}


.pageTitle {
    display: flex;
    flex-direction: column;
    gap: 20px;
}


/** BUTTON **/
.defaultButton {
    background-color: var(--light);
    display: block;
    text-align: center;
    padding: 8px 12px;
    min-width: 120px;
    border-radius: 12px;
    height: min-content;
    outline: none;
    border: 0;
    width: fit-content;
}

.centeredButton {
    align-items: center;
    justify-content: center;
    display: flex;
}

.blueButton, input[type=submit] {
    background-color: var(--main-blue);
    color: white;
}

input[type=submit]:hover {
    cursor: pointer;
}



.darkButton {
    background-color: var(--dark);
    color: white;
}

.inputDiv {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

/** Text on black section **/
.textOnBlackSection {
    color: var(--normal);
    font-size: 20px;
}

/** SECTION **/

.sectionWithDefaultPadding {
    padding: 18vh 10%;
    min-height: 100vh;
}

.blackBackground {
    background-color: var(--black);
}

.sectionPaddingTop {
    padding-top: 18vh;
}

.defaultSitePadding {
    padding: 0 10%;
}

.centeredSection {
    display: flex;
    flex-direction: column;
    gap: 80px;
    text-align: center;
    align-items: center;
}

/** INPUTS **/

form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}


input, textarea, select, option {
    border: 0;
    background-color: var(--light);
    padding: 10px 16px;
    border-radius: 12px;
    width: 100%;
    outline: none;
    min-width: 300px;
}

input[type=submit] {
    width: min-content;
    min-width: 150px;
    align-self: flex-end;
}

textarea {
    height: 20vh;
    width: 100%;
}

input:focus, textarea:focus {
    outline: 1px solid var(--normal);
}


/*** DATA SCREEN **/
.data_screen {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 60%;
    margin: 0 auto;
}

.data_screen h5 {
    color: var(--dark);
    font-size: 16px;
}

.data_rights_container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.link_underline {
    text-decoration: underline;
}

.error_container {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-direction: row;
}

/********* RESPONSIVE *************/

@media screen and (max-width: 1200px) {
    h1 {font-size: 50px;}
    h2 {font-size: 40px;}
    .data_screen {
        width: 100%;
    }
}

@media screen and (max-width: 680px) {
    h1 {font-size: 35px;}
    h2 {font-size: 25px;}
    h3 {font-size: 20px;}
    h4 {font-size: 20px;}
    h5 {font-size: 20px;}
    h6 {font-size: 20px;}
    input, a, p, textarea, th, td, select, option {font-size: 16px; color: var(--dark);line-height: 150%;}

    .sectionWithDefaultPadding {
        padding: 18vh 5%;
    }

    .centeredSection {
        gap: 50px;
    }

    input, textarea, select, option {
        width: 100%;
    }
    
    
    .defaultSitePadding {
        padding: 0 5%;
    }

/**     .sectionPaddingTop {
        padding-top: 25vh;
    }
*/
}